body {
  font-family:sans-serif;
}
.home-bg {
  margin: 1vh 1vw;
  height: 98vh;
  width: 98vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 500ms cubic-bezier(0.8, -0.5, 0.2, 1.4);
}

.home-bg-en {
  background-image: url("assets/home-en.png");
}

.home-bg-zh {
  background-image: url("assets/home-zh.png");
}

.home-bg-push {
  transform: translateY(180px);
}

.home-link-buttons {
  position: fixed;
  top: 12px;
  right: 0;
  z-index: 999;
}

.contacts {
  position:fixed;
  top: -180px;
  left: 0;
  width: 100vw;
  height: 180px;
  color: white;
  background: #214d7c;
  line-height: 34px;
  font-weight: 100;
  padding-left: 24px;
  transition: top 500ms cubic-bezier(0.8, -0.5, 0.2, 1.4);
}

.contacts span {
  display: inline-block;
  width: 80px;
}

.contacts a {
  color: white;
}

.contacts-push {
  top: 0;
}

.beian {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 4px;
  color: white;
  font-size: 14px;
  font-weight: 100;
  text-align: center;
}

.beian a {
  color: white;
  text-decoration: none;
}
