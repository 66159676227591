.dc-button {
  cursor: pointer;
  color: white;
  border: none;
  background: #214d7c;
  font-size: 18px;
  line-height: 32px;
  padding: 0 16px;
  margin: 4px;
  width: 120px;
}

.dc-button:hover {
  transform: scale(0.95);
  transition: all 200ms ease-in;
}
